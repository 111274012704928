import 'angular';

import { AccountingSettingsModel } from '../companies/models/accounting-settings';
import { CodeTypeModel }           from '../core/models/code-type';
import { ProductAccountsModel }    from './models/product-accounts';
import { ProductCategoryModel }    from './models/product-category';
import { ProductGroupModel }       from './models/product-group';

interface ProductGroupsScope extends ng.IScope {
  accountingSettings: AccountingSettingsModel;
  accounts          : Array<CodeTypeModel>;
  categories        : Array<ProductCategoryModel>;
  crudGridAPI       : any;
  getSettings       : () => void;
  loadingAccounts   : boolean;
  productGroups     : Array<ProductGroupModel>;
  purchaseAccounts  : Array<CodeTypeModel>;
  removeRow         : ( group : ProductGroupModel, index : number ) => void;
  saveRow           : ( group : ProductGroupModel, index : number ) => void;
};

export function ProductGroupsCtrl (
  $rootScope : any,
  $scope     : ProductGroupsScope,
  $translate : ng.translate.ITranslateService,
  confirm    : any,
  crudGridAPI: any,
  messages   : any,
  workshopAPI: any
) {

  $scope.accountingSettings;
  $scope.accounts           = [];
  $scope.categories         = [];
  $scope.crudGridAPI        = crudGridAPI();
  $scope.crudGridAPI.data   = $scope.productGroups;
  $scope.loadingAccounts    = true;
  $scope.purchaseAccounts   = [];

  $scope.getSettings = function () {
    $rootScope.Company.getAllAccounts()
    .then(( accounts : ProductAccountsModel ) => {
      $scope.accounts         = accounts.sale;
      $scope.purchaseAccounts = accounts.purchase;

      return accounts;
    })
    .then(() => $rootScope.Company.getAccountingSettings())
    .then(( settings : AccountingSettingsModel ) => {
      $scope.accountingSettings = settings;
    })
    .then(() => workshopAPI.get('/product_categories/product_category_list', 'true'))
    .then(( categories : Array<ProductCategoryModel> ) => {
      $scope.categories = categories;
    })
    .finally(() => {
      $scope.loadingAccounts = false;
    });
  };

  $scope.saveRow = function ( group : ProductGroupModel, index : number ) : void {
    const method = group.created_on ? 'patch' : 'post';
    const url    = group.created_on ? '/product_group' : '/product_groups';

    workshopAPI[method](url, group, 'product_group')
    .then(savedGroup => {
      if (!group.created_on) {
        $scope.crudGridAPI.data.push(savedGroup);
      } else {
        angular.extend(group, savedGroup);
      }

      $scope.crudGridAPI.toggleEditMode(group, index);

      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.PRODUCT_GROUP'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.removeRow = function ( group : ProductGroupModel, index : number ) : void {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_TEMPLATE'))
    .then(() => $scope.crudGridAPI.removeRow(group, index, '/product_group', 'name'))
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.PRODUCT_GROUP'), 'success');

      $scope.crudGridAPI.data.splice(index, 1);
    });
  };

  $scope.getSettings();
};