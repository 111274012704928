import BomActionCellHtml             from '../grids/templates/cell.bom-action.html';
import DynamicColorSelectableRowHtml from '../grids/templates/row.dynamic-color.selectable.html';
import EditableCellHtml              from '../grids/templates/cell.editable.html';
import EditableCurrencyCellHtml      from '../grids/templates/cell.currency.editable.html';
import EditableNumberCellHtml        from '../grids/templates/cell.number.editable.html';
import ProductSearchCellHtml         from './templates/product-search.cell.html';
import UnsortableHeaderCellHtml      from '../grids/templates/cell.unsortable-header.html';

export function ProductBomItemsLiteCtrl (
  $q,
  $scope,
  $translate,
  $timeout,
  gridAPI,
  messages,
  ProductSearch,
  reorderGrid,
  workshopAPI
) {
  $scope.itemsToDelete = [];
  $scope.editMode = false;

  $scope.ProductSearch = new ProductSearch({
    bomItems     : '/products/list_bom_items',
    delete       : '/bom_items',
    updatePricing: '/update_pricing'
  });

  $scope.grid = {
    columnDefs: [
      {
        cellTemplate      : BomActionCellHtml,
        displayName       : '',
        enableCellEdit    : false,
        headerCellTemplate: UnsortableHeaderCellHtml,
        width             : '52px'
      },
      {
        displayName         : $translate.instant('PRODUCT_SPACE.PRODUCT'),
        editableCellTemplate: ProductSearchCellHtml,
        field               : 'product_item_code',
        width               : '175px'
      },
      {
        displayName         : $translate.instant('GENERAL_SPACE.FIELD.DESCRIPTION'),
        editableCellTemplate: EditableCellHtml,
        enableCellEdit      : true,
        field               : 'description'
      },
      {
        cellClass           : 'align-right',
        cellFilter          : 'number',
        displayName         : $translate.instant('PRODUCT_SPACE.QTY'),
        editableCellTemplate: EditableNumberCellHtml,
        enableCellEdit      : true,
        field               : 'quantity',
        width               : '61px',
      },
      {
        cellClass           : 'align-right',
        cellFilter          : 'globalCurrency',
        displayName         : $translate.instant('JS_SPACE.COLUMNS.UNIT_PRICE'),
        editableCellTemplate: EditableCurrencyCellHtml,
        enableCellEdit      : true,
        field               : 'price',
        width               : '101px'
      },
      {
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        displayName   : $translate.instant('JS_SPACE.COLUMNS.UNIT_COST'),
        enableCellEdit: false,
        field         : $scope.company.price_includes_tax ? 'cost_including_tax' : 'unit_cost',
        width         : '101px'
      },
      {
        cellClass     : 'align-right ReadOnly',
        cellFilter    : 'globalCurrency',
        displayName   : $translate.instant('INVOICE_SPACE.LINE_TOTAL'),
        enableCellEdit: false,
        field         : 'line_total',
        width         : '101px'
      }
    ],
    data                   : 'bom_items',
    enableCellSelection    : true,
    cellEditableCondition  : '!editMode',
    enableRowSelection     : false,
    enableCellEditOnFocus  : true,
    rowHeight              : 43,
    virtualizationThreshold: 9999,
    rowTemplate            : DynamicColorSelectableRowHtml,
    total                  : 0,
  };

  $scope.setOrdering = function () {
    angular.forEach($scope.bom_items, (item, idx) => {
      item.ordering = item.ordering && item.ordering === 0 ? item.ordering : idx * 1000000;
    });
  };

  $scope.getRowColor = function (item, column) {
    const classes = gridAPI.getColumnClasses(column);

    if ($scope.editMode && item.selected) {
      classes.push('selected');
    }

    else if ($scope.editMode && item.active) {
      classes.push('active');
    }

    else if (!$scope.editMode && item.is_bom_parent) {
      classes.push('bom-parent');
    }

    else if (!$scope.editMode && item.bom_parent_id) {
      classes.push('bom-child');
    }

    return classes;
  };

  $scope.productSearch = {
    // The field that indicates an item has already been found
    identifier: 'product_id',
    list      : [],
    search (typed) {
      return $scope.ProductSearch.reservedStockSearch(typed);
    },
    /**
    * Instead of a stream search, there are
    * cases (barcode scan) where we just want
    * a quick find on the code without delaying
    * tab or anything. This is the call for that.
    * @param  {string} typed The entered value to search off of
    * @return {promise}         A promise related to the http call for product
    */
    quickSearch (typed) {
      return $scope.ProductSearch.quickSearch(typed);
    },
    format (product) {
      return angular.isObject(product) ? product.product_item_code: product;
    },
    populate (product, row) {
      $scope.bom_items[row.rowIndex].bom_product_id     = $scope.product.id;
      $scope.bom_items[row.rowIndex].cost_including_tax = product.cost_including_tax;
      $scope.bom_items[row.rowIndex].description        = product.description;
      $scope.bom_items[row.rowIndex].price              = product.retail_price;
      $scope.bom_items[row.rowIndex].product_id         = product.id;
      $scope.bom_items[row.rowIndex].product_item_code  = product.item_code;
      $scope.bom_items[row.rowIndex].product_type       = product.product_type;
      $scope.bom_items[row.rowIndex].quantity           = 1;
      $scope.bom_items[row.rowIndex].unit_cost          = product.cost;
      $scope.bom_items[row.rowIndex].untouched          = false;

    },
    choose (product, row) {
      const self = this;

      if (product.last) {
        $scope.ProductSearch.advancedSearch({
          item_code   : product.item_code,
          product_type: 'S'
        }, row).then(selection => {
          self.populate(selection.selection, row, selection.isNewProduct);
        });
      }
      else {
        self.populate(product, row);
      }
    }
  };

  $scope.toggleEditMode = function () {
    if ($scope.bom_items[0].ordering === null) {
      reorderGrid.resetIndexes($scope.bom_items);
    }

    $scope.grid.$gridScope.enableCellEditOnFocus = !$scope.grid.$gridScope.enableCellEditOnFocus;
    $scope.grid.selectAll(false);

    $scope.editMode = !$scope.editMode;

    if (!$scope.editMode) {
      $scope.$broadcast('endGridReorder');
    }

  };

  $scope.move = function (direction) {
    $scope.bomForm.$setDirty();

    return reorderGrid.moveItem($scope.bom_items, direction);
  };

  $scope.addItem = function () {
    $timeout(() => {
      console.log('New Line Item');
      $scope.bom_items.push({
        untouched: true
      });

      $scope.setOrdering();
    }, 0);
  };

  $scope.removeItem = function (row) {
    var deletedItem = $scope.bom_items.splice(row.rowIndex, 1);

    if ($scope.product.id && row.entity.id) {
      deletedItem[0].destroy = '1';
      $scope.itemsToDelete   = $scope.itemsToDelete.concat(deletedItem);
    }

    if ($scope.bom_items.length) {
      $scope.ProductSearch.updatePricing($scope.bom_items, 'bom_items').then(boms => {
        $scope.bom_items = boms.bom_items;
        $scope.grid.total = boms.total;
      }).catch(err => {
        messages.error(err);
      });
    }
  };

  $scope.deleteItems = function () {
    return $scope.itemsToDelete.length && $scope.product.id ? $scope.ProductSearch.deleteItems($scope.itemsToDelete, 'bom_items') : $q.when();
  };

  $scope.save = function (form) {
    $scope.setOrdering();

    var boms = $scope.bom_items.filter(function (bom) {
      return !bom.untouched;
    });

    $scope.deleteItems().then(() => {
      return workshopAPI.post('/bom_items', boms, 'bom_items');
    }).then(boms => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.BUNDLED_ITEMS'), 'success');

      $scope.bom_items = boms;
    }).catch(err => {
      messages.error(err);
    });
  };

  if ($scope.product.is_bom_parent && $scope.product.id) {
    $scope.ProductSearch.getBomItems({}, [$scope.product.id,  0, '*', 'id', 'desc']).then(boms => {
      if (boms.length) {
        $scope.ProductSearch.updatePricing(boms, 'bom_items').then(data => {
          $scope.bom_items = data.bom_items;
          $scope.grid.total = data.total;
        }).catch(err => {
          messages.error(err);
        });
      }
      else {
        $scope.bom_items = [];
        $scope.addItem();
      }
    });
  }
  else {
    $scope.bom_items = [];
    $scope.addItem();
  }

  $scope.$on('ngGridEventEndCellEdit', function (evt) {
    // At this moment the row isn't synced with the list of items, so we must force it
    $scope.bom_items[evt.targetScope.row.rowIndex] = evt.targetScope.row.entity;

    $scope.ProductSearch.updatePricing($scope.bom_items, 'bom_items').then(data => {
      $scope.grid.total = data.total;
      $scope.bom_items  = data.bom_items;

      if (!$scope.$$phase) {
        $scope.$apply();
      }

    }).catch(err => {
      messages.error(err);
    });
  });
};